import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Footer.scss";
import axios from "axios";
import { useState } from "react";

const Footer = () => {
  const [open, setOpen] = useState(false);
  const [conutryList, setCountryList] = useState();
  const getSiteSettingDetails = useSelector(
    (state) => state.home.getSiteSettingDetails
  );

  const wrapperRef = useRef(null);
  const wrapperRefnew = useRef(null);
  useOutsideAlerter(wrapperRef, wrapperRefnew);

  function useOutsideAlerter(ref, refnew) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !refnew.current.contains(event.target)
        ) {
          setOpen(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    axios
      .get("https://smileszy.co.uk/dashboard/api/country-list")
      .then((res) => {
        setCountryList(res.data.home);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="Footer-wrapper">
        <div className="container">
          <div className="footer-links-wrapeer">
            <div className="row">
              <div className="col-lg-2 col-md-6 sm-12">
                <div className="Store-details-wrapee">
                  <p className="footer-link-heading">Buy</p>
                  <p>
                    <Link to="/registration">Registration</Link>
                  </p>
                  <p>
                    <Link to="/moneybackguarantee">Money Back Guarantee</Link>
                  </p>
                  <p>
                    <Link to="/biddingbuyinghelpss">Bidding&buying help</Link>
                  </p>
                  <p>
                    <Link to="/user-agreement">User Agreement</Link>
                  </p>
                  <p>
                    <Link to="/feedback">Feedback guideline</Link>
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 sm-12">
                <div className="footerlinks-wrap ">
                  <p className="footer-links-heading">Sell</p>
                  <ul className="foterlinkswraped">
                    <Link to="/sell">
                      <li>Start selling</li>
                    </Link>
                    <Link to="/affiliates">
                      <li>Affiliates</li>
                    </Link>
                  </ul>
                  <div className="footerlinks-wrap ">
                    <p className="footer-links-heading">Tools & apps</p>
                    <ul className="foterlinkswraped">
                      {/* <Link to="/securityCenter">
                        <li>Security center</li>
                      </Link> */}
                      <Link to="/sitemap">
                        <li>Site map</li>
                      </Link>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 sm-12 About-Smileszy">
                <div className="categories-wrapperd">
                  <div className="footerlinks-wrap ">
                    <p className="footer-links-heading">Our apps</p>
                    <ul className="foterlinkswraped hehhhjshhjsh">
                      <li>
                        <a
                          href="https://apps.apple.com/in/app/smileszy/id1636843434"
                          target="blank"
                        >
                          <img src="/footericons/appstore.png" alt="" />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.smlieszy"
                          target="blank"
                        >
                          <img src="/footericons/playstore.png" alt="" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 sm-12 Quick-Links">
                <div className="footerlinks-wrap">
                  <p className="footer-links-heading">Quick Links</p>
                  <ul className="foterlinkswraped">
                    <Link to="/about">
                      <li>About Us</li>
                    </Link>
                    <Link to="/products">
                      <li>Products</li>
                    </Link>
                    <Link to="/FaqPage">
                      <li>FAQ</li>
                    </Link>
                    <Link to="/blog">
                      <li>Blog</li>
                    </Link>
                    <Link to="/how-it-work">
                      <li>How its work</li>
                    </Link>
                    <Link to="/dailyDeals">
                      <li>Deals</li>
                    </Link>
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 sm-12 ">
                <div className="categories-wrapperd">
                  <div className="categorilinks-wrap">
                    <p className="footer-links-heading">Stay Connected</p>
                    <div className="categreslink-wraped">
                      <ul className="categorilinkswraped">
                        <a
                          href={
                            getSiteSettingDetails &&
                            getSiteSettingDetails.facebook
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <i className="fa-brands fa-facebook-square me-2"></i>
                            Facebook
                          </li>
                        </a>
                        <a
                          href={
                            getSiteSettingDetails &&
                            getSiteSettingDetails.twitter
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <i className="fa-brands fa-twitter-square me-2"></i>
                            Twitter
                          </li>
                        </a>
                        <a
                          href={
                            getSiteSettingDetails &&
                            getSiteSettingDetails.instagram
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <li>
                            <i className="fa-brands fa-instagram-square me-2"></i>
                            Instagram
                          </li>
                        </a>
                      </ul>
                    </div>
                  </div>
                  <div className="categorilinks-wrap">
                    <p className="footer-links-heading"> Help & Contact</p>
                    <div className="categreslink-wraped">
                      <ul className="categorilinkswraped">
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          to="/contact"
                        >
                          <li>Contact Us</li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 sm-12 About-Smileszy">
                <div className="categories-wrapperd">
                  <div className="categorilinks-wrap">
                    <p className="footer-links-heading">About Smileszy</p>
                    <div className="categreslink-wraped">
                      <ul className="categorilinkswraped">
                        {/* <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          to="/company-info"
                        >
                          <li>Company info</li>
                        </Link> */}
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          to="/return"
                        >
                          <li>Return Policy</li>
                        </Link>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          to="/payment-services"
                        >
                          <li>Payment Services</li>
                        </Link>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          to="/privacy"
                        >
                          <li>Privacy Policy</li>
                        </Link>
                        <Link
                          style={{
                            textDecoration: "none",
                            color: "white",
                          }}
                          to="/terms"
                        >
                          <li>Terms and Conditions.</li>
                        </Link>
                      </ul>
                    </div>
                  </div>
                  <div className="categorilinks-wrap">
                    <p className="footer-links-heading">Smileszy Sites</p>
                    <div
                      ref={wrapperRefnew}
                      className="Smileszy-SitesWrapper"
                      onClick={() => setOpen(!open)}
                    >
                      <img
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcREPfrsS5joh32bp-dy6DBSn1clorgPxOpa3Q&usqp=CAU"
                        alt=""
                        className="img-fluid"
                      />
                      <span>United Kingdom</span>
                      <i class="fa fa-sort-desc" aria-hidden="true"></i>
                    </div>
                    {open && (
                      <div className="country-popupList" ref={wrapperRef}>
                        {conutryList &&
                          conutryList.map((item, key) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                width: "50%",
                              }}
                            >
                              <img
                                src={item.icon}
                                alt=""
                                className="img-fluid"
                                style={{ width: "40px", height: "30px" }}
                              />
                              <span
                                style={{
                                  marginLeft: "5px",
                                  textDecoration: "underline",
                                }}
                              >
                                {item.name}
                              </span>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer copyright-bg">
          <div className="container">
            <div className="footer-copy-wrapper">
              <div>Copyright © 2021 Smileszy. All rights reserved.</div>
              <div className="flot-end">
                <img src="/footericons/card-4.png" alt="#" />{" "}
                <img src="/footericons/card-5.png" alt="#" className="ms-2" />{" "}
                <img src="/footericons/card-4.png" alt="#" className="ms-2" />{" "}
                <img src="/footericons/card-4.png" alt="#" className="ms-2" />{" "}
                <img src="/footericons/card-5.png" alt="#" className="ms-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
